import React, { useEffect } from 'react';
import './App.css';
import NavigationBar from './components/navigation/NavigationBar';
import Footer from './components/footer/Footer';
import EmailScreen from './screens/emailScreen/EmailScreen';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import WaiverScreen from './screens/waiverScreen/WaiverScreen';
import RegistrationScreen from './screens/registrationScreen/RegistrationScreen';
import WelcomeScreen from './screens/welcomeScreen/WelcomeScreen';
import AlmostDoneScreen from './screens/almostDoneScreen/AlmostDoneScreen';
import VerifyCodeScreen from './screens/verifyCodeScreen/VerifyCodeScreen';
import LandlineScreen from './screens/landlineScreen/LandlineScreen';
import VerificationCompleteScreen from './screens/verificationCompleteScreen/VerificationCompleteScreen';
import PhoneScreen from './screens/phoneScreen/PhoneScreen';
import { loadAudioEyeScript } from './components/navigation/audioeyeScript';

function App() {

  useEffect(()=>{
    loadAudioEyeScript();
  },[])

  return (
    <div className="main-container">
      <NavigationBar />
      <BrowserRouter>
        <Routes>
          
          <Route path="login">
            <Route path='' element={<EmailScreen />} />
            <Route path='phone' element={<PhoneScreen />} />
            {/* <Route path='2' element={<WaiverScreen />} /> */}
            {/* <Route path='3' element={<RegistrationScreen isReferral={false} />} /> */}
            {/* <Route path='4' element={<WelcomeScreen />} /> */}
            {/* <Route path='5' element={<AlmostDoneScreen />} /> */}
            <Route path='verification' element={<VerifyCodeScreen />} />
            <Route path='verification-complete' element={<VerificationCompleteScreen />} />
            <Route path='landline' element={<LandlineScreen />} />
          </Route>

          <Route path="signup">
            <Route path='' element={<RegistrationScreen />} />
          </Route>

        </Routes>
      </BrowserRouter>
      <Footer />
    </div>

  );
}

export default App;
