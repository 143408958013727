import React, { useEffect, useState } from 'react';
import televedaLogo from './televeda-logo 4.svg';
import chandlerLogo from './chandler-logo.svg';
import accessibilityIcon from './accessibilityIcon.png';
import './NavigationBar.css';
import { loadAudioEyeScript } from './audioeyeScript';
import { SignupAPI } from '../../logic/signupApi/signupApi';

const NavigationBar = () => {

  // TO-DO: Extract and refactor the state of the logo

  const [logo, setLogo] = useState<string | null>(null);
  const [logoName, setLogoName] = useState('navigation-logo');
  const [communityName, setCommunityName] = useState('');

  const chandlerPrimaryColor = '194167';

  const setPrimaryColor = (hexCode: string) => {
    window.localStorage.setItem('primaryColor', hexCode);
    document.body.style.setProperty('--primary', `#${hexCode}`)
    document.body.style.setProperty('--primary-lighter', `#${hexCode}66`)
    document.body.style.setProperty('--primary-light', `#${hexCode}33`)
    setLogo(chandlerLogo);
    setLogoName('navigation-logo-community')
  }

  const checkReferralId = async () => {
    let url = new URL(window.location.href);

    const code = url.searchParams.get('ref');

    if (!code) {
      setLogo(televedaLogo);
      setLogoName('navigation-logo');
    }
    else {
      setLogoName('navigation-logo-community');
      
      const rawCode = atob(code);

      await SignupAPI.checkIfInviteCodeIsValid(rawCode).then((result: any) => {
        if(result.communityLogo) {
          setLogo(result.communityLogo)
        }
        if(result.communityName){
          setCommunityName(result.communityName);
        }
        else {
          setLogo(televedaLogo);
        }
      }).catch((error) => {
        console.log('error while checking invite code: ', error);
        setLogo(televedaLogo);
      })
    }
  }

  useEffect(()=>{
    let primaryColor = window.localStorage.getItem('primaryColor');
    if(primaryColor){
      setPrimaryColor(primaryColor);
    }

    loadAudioEyeScript();
    checkReferralId();
  },[])

  console.log(logo)
  
  return (
    <div className='navigation-bar'>
        {logo && <img src={logo} className={logoName} />}
        {communityName && <div className='navigation-community-title text-community-label'>{communityName}</div>}
        {/* <img src={accessibilityIcon} className='navigation-button' onClick={() => setPrimaryColor(chandlerPrimaryColor)}/> */}
    </div>
  )
};

export default NavigationBar