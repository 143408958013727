import React from 'react';
import './ErrorMessage.css';
import closeIcon from "./close.png";

interface ErrorMessageProps {
    message: string
    onClose: () => void;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <div className='error-message-container'>
        <button className='error-message-close-btn' onClick={()=> props.onClose()}><img className='close-icon' src={closeIcon}/></button>
        <div>
          <text className='error-message-text'>
            {props.message}
          </text>
        </div>
    </div>
  )
};

export default ErrorMessage